import { Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { SupportedLanguages } from '../../interfaces/languages.enum';
import { SessionRepository } from '../repositories/storage.repository';
import { SessionConfig } from './session-config.interface';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    private readonly sessionRepository = inject(SessionRepository);

    getSessionConfiguration$(): Observable<SessionConfig> {
        return this.sessionRepository.getSession$();
    }

    setLanguage(language: SupportedLanguages) {
        this.sessionRepository.setLanguage(language);
    }
}
