import { BehaviorSubject } from 'rxjs';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { NgVariableDirective } from '@cgib/ui-shared/directives/ng-variable.directive';

@Component({
    selector: 'cgib-notification',
    standalone: true,
    imports: [CommonModule, NgVariableDirective],
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent {
    @Input() set message(msg: string | null) {
        if (msg) {
            this.message$.next(msg);
            this.hide$.next(false);
        }
    }
    @Input() type = 'error';

    @Output() closeNotification = new EventEmitter<void>();

    message$ = new BehaviorSubject<string | undefined>(void 0);
    hide$ = new BehaviorSubject<boolean>(true);

    hide() {
        this.message$.next(void 0);
        this.hide$.next(true);
    }
}
