import { Observable, map } from 'rxjs';

import { Injectable } from '@angular/core';

import { AssetDto } from '@cgib/shared/dtos/asset.dto';
import { PaginationResult } from '@cgib/shared/dtos/pagination-result.dto';
import { PaginationInputDto } from '@cgib/shared/dtos/pagination.input.dto';
import { SubjectDto } from '@cgib/shared/dtos/search/subject.dto';
import { AssetTypeEnum } from '@cgib/shared/enums/asset-type.enum';
import { VisualSearchTypeEnum } from '@cgib/shared/enums/visual-search-type.enum';

import { BaseGraphqlService } from './base-graphql.service';
import { GET_PRESIGNED_URL, GET_VISUAL_SEARCH_RESULTS, GET_VISUAL_SEARCH_RESULTS_V2 } from './queries';

@Injectable({
    providedIn: 'root'
})
export class VisualSearchGraphqlService extends BaseGraphqlService {
    getVisualSearchResults$(
        pagination: PaginationInputDto,
        fileUrl: string,
        type: AssetTypeEnum
    ): Observable<PaginationResult<AssetDto>> {
        return this.query$<{ getVisualSearchResults: PaginationResult<AssetDto> }>({
            query: GET_VISUAL_SEARCH_RESULTS,
            variables: {
                pagination,
                fileUrl,
                type
            },
            headers: {
                'x-client-id': this.config.cgibClientId
            }
        }).pipe(map(result => result.getVisualSearchResults));
    }
    getVisualSearchResultsV2$(
        pagination: PaginationInputDto,
        fileUrl: string,
        type: VisualSearchTypeEnum
    ): Observable<PaginationResult<AssetDto | SubjectDto>> {
        return this.query$<{ getVisualSearchResultsV2: PaginationResult<AssetDto | SubjectDto> }>({
            query: GET_VISUAL_SEARCH_RESULTS_V2,
            variables: {
                pagination,
                fileUrl,
                type
            },
            headers: {
                'x-client-id': this.config.cgibClientId
            }
        }).pipe(map(result => result.getVisualSearchResultsV2));
    }

    getPresignedUrl$(key: string, bucket: string, prefix: string) {
        return this.query$<{ createPresignedUrl: string }>({
            query: GET_PRESIGNED_URL,
            variables: { key, prefix, bucket },
            headers: {
                'x-client-id': this.config.cgibClientId
            },
            fetchPolicy: 'network-only'
        }).pipe(map(result => result?.createPresignedUrl));
    }
}
