<div
    class="rounded-md p-4 relative"
    [ngClass]="{ 'bg-red-50/30': message, hidden: (hide$ | async) === true }"
    *ngVariable="message$ | async as message"
>
    <button
        #hideNotification
        id="hideNotification"
        class="absolute right-1 top-1 z-20 text-gray-700 cursor-pointer rounded-full border border-transparent p-2 hover:text-gray-900"
        (click)="hide()"
    >
        <svg
            class="h-6 w-6"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            a
            stroke-linejoin="round"
        >
            <path d="M0 0h24v24H0z" stroke="none" />
            <path d="M18 6L6 18M6 6l12 12" />
        </svg>
    </button>
    <div class="flex justify-between">
        <div class="flex">
            <div class="flex-shrink-0" [ngClass]="{ invisible: !message }">
                <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                        clip-rule="evenodd"
                    />
                </svg>
            </div>
            <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800" [ngClass]="{ invisible: !message }">{{ message }}</h3>
            </div>
        </div>
    </div>
</div>
