import { Injectable } from '@angular/core';

import { createStore, select, withProps } from '@ngneat/elf';
import { SupportedLanguages } from '../../interfaces/languages.enum';

interface SessionProps {
    language: SupportedLanguages;
}

const sessionStore = createStore(
    { name: 'session-store' },
    withProps<SessionProps>({ language: SupportedLanguages.EN })
);

@Injectable({
    providedIn: 'root'
})
export class SessionRepository {
    getSession$() {
        return sessionStore.pipe(select(state => state));
    }

    setLanguage(language: SessionProps['language']) {
        sessionStore.update(state => ({ ...state, language }));
    }
}
