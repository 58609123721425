import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[cgibDnd]',
    standalone: true
})
export class DndDirective {
    //@HostBinding('class.fileover') fileOver = false;
    @Output() fileDropped = new EventEmitter<FileList>();
    // @Output() dragOver = new EventEmitter<boolean>();

    @HostListener('dragover', ['$event']) onDragOver(evt: Event) {
        evt.preventDefault();
        evt.stopPropagation();
        //this.dragOver.emit(true);
    }
    @HostListener('dragleave', ['$event']) public onDragLeave(evt: Event) {
        evt.preventDefault();
        evt.stopPropagation();
        //this.fileOver = false;
        //this.dragOver.emit(false);
    }
    // Drop listener
    @HostListener('drop', ['$event']) public ondrop(evt: Event & { dataTransfer: { files: FileList } }) {
        evt.preventDefault();
        evt.stopPropagation();
        //this.fileOver = false;
        const files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.fileDropped.emit(files);
        }
    }
}
