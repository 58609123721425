/* eslint-disable @angular-eslint/directive-selector */
import { tap } from 'rxjs';

import { Directive, InjectionToken, Input, OnInit, TemplateRef, ViewContainerRef, inject, signal } from '@angular/core';

import { SubscriptionStateEnum } from '@cgib/shared/enums/subscription-state.enum';
import { UserPermissionEnum } from '@cgib/shared/enums/user-permission.enum';
import { StripeConfig } from '@cgib/ui-shared/components/pricing-selection/pricing-selection.component';
import { QuotaRepository } from '@cgib/ui-shared/repositories/quota.repository';

import { UserRepository } from '../repositories/user.repository';

export const STRIPE_CONFIG = new InjectionToken<StripeConfig>('STRIPE_CONFIG');
@Directive({
    standalone: true,
    selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit {
    private quotaRepository = inject(QuotaRepository);

    stripeConfig = inject(STRIPE_CONFIG);

    @Input()
    set hasPermission(permissions: string[]) {
        this.requestedPermissions = permissions;
        this.updateView();
    }
    @Input()
    set hasPermissionOp(permop: 'AND' | 'OR') {
        this.logicalOp = permop;
        this.updateView();
    }
    private userPermissions: string[] = [];
    private requestedPermissions: string[] = [];
    private logicalOp: 'AND' | 'OR' = 'AND';
    private isHidden = true;
    private subscriptionPriceId = signal<string | undefined>(undefined);

    constructor(
        private templateRef: TemplateRef<unknown>,
        private viewContainer: ViewContainerRef,
        private userRepository: UserRepository
    ) {
        this.quotaRepository
            .getQuota$()
            .pipe(
                tap(quota => {
                    if (quota.activeSubscription?.subscriptionState === SubscriptionStateEnum.ACTIVE) {
                        this.subscriptionPriceId.set(quota.activeSubscription.subscriptionPriceId);
                        //this.updateView();
                    }
                })
            )
            .subscribe();
    }

    ngOnInit() {
        this.userRepository
            .getPermissions$()
            .pipe(
                tap(permissions => {
                    this.userPermissions = permissions;
                    this.updateView();
                })
            )
            .subscribe();
    }

    private updateView() {
        if (this.checkPermission()) {
            if (this.isHidden) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.isHidden = false;
            }
        } else {
            this.isHidden = true;
            this.viewContainer.clear();
        }
    }

    private checkPermission() {
        let hasPermission = false;

        if (this.userPermissions) {
            for (const checkPermission of this.requestedPermissions) {
                const permissionFound = this.userPermissions.find(
                    x => x.toUpperCase() === checkPermission.toUpperCase()
                );

                if (permissionFound) {
                    hasPermission = true;

                    if (this.logicalOp === 'OR') {
                        break;
                    }
                } else {
                    hasPermission = false;
                    if (this.logicalOp === 'AND') {
                        break;
                    }
                }
            }
        }

        if (this.requestedPermissions.includes(UserPermissionEnum.FEATURE_AI_GENERATE)) {
            if (
                [
                    this.stripeConfig.freelance.monthlySubscription,
                    this.stripeConfig.freelance.yearlySubscription,
                    this.stripeConfig.pro.monthlySubscription,
                    this.stripeConfig.pro.yearlySubscription
                ].includes(this.subscriptionPriceId() ?? '')
            ) {
                hasPermission = true;
            }
        }

        // if (this.requestedPermissions.includes(UserPermissionEnum.SCENE_READ)) {
        //     if (
        //         [
        //             this.stripeConfig.pro.monthlySubscription,
        //             this.stripeConfig.pro.yearlySubscription
        //         ].includes(this.subscriptionPriceId() ?? '')
        //     ) {
        //         hasPermission = true;
        //     }
        // }

        return hasPermission;
    }
}
